
import PDETimelineLineItem from '@/components/pde/PDETimelineLineItem.vue'

export default {
  name: 'PDETimelineItem',
  components: {
    PDETimelineLineItem,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
}
