
import ItineraryHeader from '@/components/account/Travel/ItineraryHeader.vue'
import DayItineraryAccordion from '@/components/account/Travel/DayItineraryAccordion.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDESkeleton from '@/components/pde/PDESkeleton.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TravelItineraryDetail',
  components: {
    ItineraryHeader,
    DayItineraryAccordion,
    PDEButton,
    PDEHeading,
    PDESkeleton,
    PDEIcon,
  },
  computed: {
    ...mapGetters('account', ['division']),
    ...mapGetters('travel', ['isLoadingGetter', 'travelItineraryDetail']),
    getHelpLabel() {
      return this.$featureFlags.travelPhoneNumber10399 ? 'Travel Help' : 'Get Help'
    },
  },
  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    viewFullItineraryClick() {
      this.sendClickEvent({
        name: 'Action Pane View Full Itinerary Click',
        text: 'View Full Itinerary',
        area: 'action-pane/travel',
        position: `${this.travelItineraryDetail.timelinePosition} Travel`,
      })
      const path = this.travelItineraryDetail.isPastTravel ? '/travel/completed' : '/travel'
      this.$router.push({ path, query: { pnr: this.travelItineraryDetail.pnr } })
    },
  },
}
