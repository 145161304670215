
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'PDETimelineLineItem',
  components: {
    PDEIcon,
  },

  props: {
    item: {
      type: Object,
      default: () => ({
        subNode: false,
        icon: 'point',
      }),
    },
  },
}
