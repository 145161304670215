
import PDETimelineItem from '@/components/pde/PDETimelineItem.vue'
import PDETimelineLineItem from '@/components/pde/PDETimelineLineItem.vue'
import UIAddressLink from '@/components/ui/UIAddressLink/UIAddressLink.vue'
import { mapActions } from 'vuex'

export default {
  name: 'CarTimelineItem',

  components: {
    PDETimelineItem,
    PDETimelineLineItem,
    UIAddressLink,
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    loyaltyNumber() {
      return this.item.loyaltyId && this.item.loyaltyId.toLowerCase() !== '@web' && this.item.loyaltyId.toLowerCase().indexOf('@xxxx') < 0 ? this.item.loyaltyId : ''
    },
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent']),

    carRentalLocationClicked() {
      this.sendClickEvent({
        name: 'Travel Location Click',
        text: 'Car Rental Location',
        area: 'travel/itinerary',
      })
    },
  },
}
