
import PDETimeline from '@/components/pde/PDETimeline.vue'
import FlightTimelineItem from './FlightTimelineItem.vue'
import CarTimelineItem from './CarTimelineItem.vue'
import HotelTimelineItem from './HotelTimelineItem.vue'

export default {
  name: 'DayItineraryAccordion',

  components: {
    PDETimeline,
    FlightTimelineItem,
    CarTimelineItem,
    HotelTimelineItem,
  },

  props: {
    details: {
      type: Object,
      default: () => ({}),
    },

    isPastTravel: {
      type: Boolean,
      default: false,
    },

    pnr: {
      type: String,
      default: null,
    },
  },

  computed: {
    displayDate() {
      return this.$dayjs(this.details.sortDate, 'MMM-DD-YYYY').format('dddd, MMMM D, YYYY')
    },
  },

  methods: {
    isConfirmed: status => status.toLowerCase() === 'confirmed',
    showFlightFooter(index) {
      const remainingBookings = this.details.travelDetails.slice(index + 1)
      const nextFlight = remainingBookings.find(b => b.type === 'flight')

      return !nextFlight
    },
  },
}
