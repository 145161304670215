
import PDETimelineItem from '@/components/pde/PDETimelineItem.vue'

export default {
  name: 'PDETimeline',
  components: { PDETimelineItem },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
