import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "address--text"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.type === 'link')
    ? (_openBlock(), _createElementBlock("a", _mergeProps({ key: 0 }, _ctx.$attrs, {
        href: `https://maps.google.com/?q=${$props.address}`,
        target: "_blank"
      }), _toDisplayString($props.title || $props.address), 17, _hoisted_1))
    : ($props.type === 'text')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.address), 1))
      : _createCommentVNode("", true)
}