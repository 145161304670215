
import PDETimelineItem from '@/components/pde/PDETimelineItem.vue'
import PDETimelineLineItem from '@/components/pde/PDETimelineLineItem.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import { mapActions } from 'vuex'
import PDESvg from '@/components/pde/PDESvg.vue'

export default {
  name: 'FlightTimelineItem',
  components: { PDETimelineItem, PDETimelineLineItem, PDEButton, PDEIcon, PDESvg },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent']),

    flightCheckin() {
      this.sendClickEvent({
        name: 'Travel Airline Check In Click',
        text: 'Airport Check In',
        area: 'travel/itinerary',
      })

      window.open(this.item.checkinLink, '_blank')
    },

    isWithin24hrs(departure) {
      return this.$dayjs(departure).diff(new Date(), 'hour') <= 24
    },
  },
}
